@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground font-codecpro;
  }
}
/* html {
    font-size:85% ;

} */

body {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #2C449A;
}

::-webkit-scrollbar-thumb {
  background: gray;
}

.select-fit {
  width: -webkit-fill-available
}

.stopScroll {
  overflow: hidden;
}

/* input, textarea {
    padding: 0.5rem 1rem !important;
    border: 1px solid #d9d9d9 !important;
    transition: all 300ms;
} */
 input:not(.otp input), textarea:not(.otp textarea) {
    padding: 0.5rem 1rem !important;
    border: 1px solid #d9d9d9 !important;
    transition: all 300ms;
}
input:focus, select:not(.no-force):focus {
    outline: 3px solid #2c449a70 !important;
}
select:not(.no-force) {
    padding: 0.64rem 1rem;
    border: 1px solid #d9d9d9 !important;
}


label[for="tokenType"] + .border.border-foreground.bg-inherit.rounded-md.w-full.h-fit {
    border: none;
}
form button, main button, a.bg-primary_blue {
	padding: 0.5rem 1rem !important;
	transition: all 250ms !important;
}

form button:active, main button:active, a.bg-primary_blue:active {    
	transform: scale(0.95) !important;
}


input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}